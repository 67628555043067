<template>
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
  
        <!-- Brand logo-->
        <b-link class="brand-logo">
            <router-link to="/">
            <img :src="require('@/assets/vagsis_logo.png')" alt="logo" />  
          </router-link>
  
          
        </b-link>
        <!-- /Brand logo-->
  
        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Forgot password V2"
            />
          </div>
        </b-col>
        <!-- /Left Text-->
  
        <!-- Forgot password-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title class="mb-1">
              Şifremi Unuttum? 🔒
            </b-card-title>
            <b-card-text class="mb-2">
             Lütfen Vagsis'e kayıtlı e-posta adresinizi girin.<br> 
             Şifre sıfırlama linki E-posta adresinize gönderilecektir.
            </b-card-text>
  
            <!-- form -->
            <validation-observer ref="simpleRules">
              <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="validationForm"
              >
                <b-form-group
                  label="Email"
                  label-for="forgot-password-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="forgot-password-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="forgot-password-email"
                      placeholder="kullanıcı@mail.com"
                    />
                    <small v-if="errors.length > 0" class="text-danger">{{ 'E-posta adresi zorunludur lüfen geçerli bir mail adresi giriniz' }}</small>
                  </validation-provider>
                </b-form-group>
  
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                Sıfırlama linkini gönder
                </b-button>
              </b-form>
            </validation-observer>
  
            <p class="text-center mt-2">
              <b-link :to="{name:'auth-login'}">
                <feather-icon icon="ChevronLeftIcon" /> Giriş sayfasına git
              </b-link>
            </p>
          </b-col>
        </b-col>
        <!-- /Forgot password-->
      </b-row>
    </div>
  </template>
  
  <script>
  /* eslint-disable global-require */
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import {
    BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
  } from 'bootstrap-vue'
  import { required, email } from '@validations'
  import store from '@/store/index'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import {Api} from '@/helpers/Api/apiConfig'
  export default {
    components: {
      VuexyLogo,
      BRow,
      BCol,
      BLink,
      BImg,
      BForm,
      BButton,
      BFormGroup,
      BFormInput,
      BCardTitle,
      BCardText,
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        userEmail: '',
        sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
        // validation
        required,
        email,
      }
    },
    computed: {
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
          return this.sideImg
        }
        return this.sideImg
      },
    },
    methods: {
        // forgotPassword() {
        //     Api.post('/auth/forgot-password', {
        //         email: this.userEmail
        //     }).then(response => {
        //         console.log(response.data)
        //     })
        // },
        
      validationForm() {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            Api("POST", "User/ForgetPassword" ,{
            username: this.userEmail,

          
          }).then(response => {
            console.log(response.data)
            if(response.data.isSuccess==true){
                
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'İşlem Başarılı!',
                text: 'Şifre sıfırlama linki e-posta adresinize gönderilmiştir. Lütfen kontrol edin.',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            }else{

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Geçersiz e-posta adresi. Lütfen tekrar deneyin.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            }

          }).catch(error => {
            console.log(error)
          })



          }
        })
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
  